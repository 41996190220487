import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';
import { fileURLToPath } from 'url';

export const passwordMinLength = 8;

function PasswordValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        var valid = true;
        var passLength = (control.value + '').length;
        valid = passLength >= passwordMinLength;
        return valid ? null : { invalidPassword: { minLength: passwordMinLength, actualLength: passLength } };
    };
}


function PasswordMismatch(fieldName1?: string, fieldName2?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {

        fieldName1 = fieldName1 ? fieldName1 : 'newPassword';
        fieldName2 = fieldName2 ? fieldName2 : 'renewPassword';

        var pass1 = control.get(fieldName1).value;
        var pass2 = control.get(fieldName2).value;

        console.log('pass1', pass1, 'pass2:', pass2)

        return pass1 && pass2 && (pass1 !== pass2) ? { passwordMismatch: true } : null;
    };
}

export const CustomValidators = {
    Password: PasswordValidator,
    PasswordMismatch: PasswordMismatch
}
