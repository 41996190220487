import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, from, of } from 'rxjs';
import { map, catchError, filter } from 'rxjs/operators';
import { ConfirmAlertComponent } from './alerts/confirm-alert/confirm-alert.component';

export const enum DialogResult {
  ok = 'ok',
  cancel = 'cancel'
};

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private modSrv: NgbModal
  ) { }

  /**
 * Shows a general confirmation dialogue box with ok, cancel 
 * @returns Only returns DialogResult.ok if user press ok, otherwise observable is completed without emit.
 * @param title title to show in the dialogue box
 * @param message message to show. Can be an HTML elemnt. Ex. 'Desea eleminar al usuario <b>FULANO</b> ?'
 */
  showConfirmDialogue(title: string, message: string): Observable<DialogResult> {
    const modRef = this.modSrv.open(
      ConfirmAlertComponent,
      {
        
      }
    );
    modRef.componentInstance.title = title;
    modRef.componentInstance.message = message;
    return from(modRef.result).pipe(
      map(x => DialogResult.ok),
      catchError(err => of(DialogResult.cancel)),
      filter(x => x === DialogResult.ok)
    );
  }

}
